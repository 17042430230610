<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;"><span style="color:#00c8d8;">Plumbing Contractor Software: </span>Simplify Your Scheduling and Docketing Process</h1>
          <div>
                <p><b>Contractors of plumbing businesses use Record TIME as plumbing contractor software and find it very efficient and prompt.</b>
                    <br><br>
                    Coordinating multiple projects, technicians, and appointments efficiently can be a complex task for any contractor in plumbing services. Managing multiple projects simultaneously while ensuring they are completed on time and within budget is another challenge. Therefore, Record TIME facilitates an innovative approach that digitalises all daily operations in plumbing field service. With Record TIME, you can have all your essential data, including customer information and work orders, at your fingertips, accessible anytime, anywhere in real time. Clearly, Record TIME is more than just business management software; it's a game-changer for your plumbing contracting business. With its user-friendly interface and intuitive features, you can efficiently organise your tasks, monitor progress, manage customer service and keep track of your team's performance, all from a centralised platform. You can also share real-time updates on project status with your team members, ensuring that your field service operations run smoothly.</p>
                </div>
                <br><br>
                <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/remote-approval-of-site-work')" class="mr-7 btn-1" elevation="0">
                    Read More
                </v-btn>
                <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn><br/><br/>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/whoweserve/plumbing-contractor-software.webp`" alt="all-in-one plumbing contractor software"  style="max-width: 90%;">
            </div>
        <div style="clear: both;"></div>
        </div>

        <v-container>
            <div class="gray-container">  
                     <div class="left-blog1">
                         <br>
                         <h3>Empowering Plumbing Contractors to Take Control Over Business Operations with Plumbing Contractor Software</h3>
                         <p style="text-align:left;">Record TIME equips plumbing contractors with a comprehensive set of solutions to streamline their operations and achieve greater control over their business. With its digital docketing approach, plumbing companies can easily digitalise their documentation process. As a result, there won't be any manual paperwork and all important documents for your business will be safely preserved in the dashboard. This not only saves time and cost but also makes it easy to access any dockets and forms whenever needed.
                            <br><br> Furthermore, this plumbing service software facilitates remote approval of documents, which expedites decision-making processes. Users can share important documents with contractors, employees, clients, suppliers, or other stakeholders and receive approvals remotely, reducing delays caused by physical meetings and paperwork.
                        <br><br>Also, plumbers can use Record TIME to provide proof of completed work with instant access to photos. This feature not only ensures accountability but also serves as evidence for clients and stakeholders. It builds trust with customers and helps in resolving disputes, if any, regarding the quality of work performed.
                       </p>
     
                     </div>
                     <div class="right-blog1">
                        <br><br>
                       <img :src="`${publicPath}assets/whoweserve/record-time-in-plumbing-business.webp`" alt="create an accurate employee timesheet and job site manager for your plumbing contracting business">
                     </div>
                     <div style="clear:both;"></div>
            </div>
                </v-container>

        <v-container>

            <h2 style="font-size:42px; text-align:center;">Accurate Employee Time Sheet and Easy Job Scheduling</h2>
            <p>Record TIME's clock-in and clock-out function is useful for accurate <a href="https://recordtime.com.au/field-staff-real-time-tracking">field staff real time tracking</a>. This feature helps in generating precise employee time sheets, which are essential for calculating wages, managing overtime, and evaluating workforce productivity. Plumbing contractors can have a clear view of their labour costs. As a result, they will effectively manage their daily operations and workforce.
                <br><br>In addition, contractors of <a href="https://recordtime.com.au/blog/record-time-for-plumbing-business">plumbing businesses</a> can optimise their workflow by planning job management for field staff. They can ensure that each job is properly sequenced and allocated to the right field technicians in the specific location, preventing conflicts and improving overall productivity.
             </p>
             <br>
             <center><img :src="`${publicPath}assets/whoweserve/why-record-time-for-plumbing-business.webp`" alt="advantage of using record time in plumbing business" style="width: 100%;"></center>
                 
     
        <br>
                <h4>
If you want to expand your plumbing business, you must select the correct software solution. Whether you're a seasoned plumbing contracting company or just starting, Record TIME will meet your unique needs. Embrace the power of modern technology, optimise your operations, and elevate your business to new heights with our software solution by your side. It is a field service software that makes it simple to dispatch your field staff. The dynamic dispatch board enables business owners to allocate the best plumber for each plumbing service depending on their abilities, job details and proximity to the project. It is an all-in-one plumbing business software for business owners.</h4>
            
                  
                        <br>
                        <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/contact')" class="mr-7 btn-1" elevation="0">
                    Contact Us
                </v-btn>
                
        </v-container>


       
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: left;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 37.5rem);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>

import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Plumbing Contractor Software to automate business | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Plumbing contractor software for businesses to streamline scheduling, dynamic dispatch, manage and automate companies workflow.'},
      {name: 'keyword', content: 'plumbing contractor software'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/plumbing-contractor-software' }
    ]
  },
  
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
